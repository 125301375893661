import { FieldTypeEnum } from "@/core/enums/field-types";

export const projetoAnaliseFields = [
  {
    key: "agrupamento_titulo",
    name: "Nome do Agrupamento",
    type: FieldTypeEnum.TEXT,
    editable: false,
    hideInForm: true,
  },
  {
    key: "id_projeto",
    name: "Código do Projeto",
    type: FieldTypeEnum.TEXT,
    rules: [{ rule: "required" }],
    colSize: 4,
  },
  {
    key: "titulo",
    name: "Nome do Projeto",
    type: FieldTypeEnum.TEXT,
    rules: [{ rule: "required" }, { rule: "min", params: { size: 3 } }, { rule: 'max', params: { size: 250 } }],
    colSize: 8,
  },
  {
    key: "ano_base",
    name: "Ano base",
    type: FieldTypeEnum.SELECT,
    rules: [{ rule: "required" }],
    rel: { to: "projectsOptions", key: "value", name: "text" },
    colSize: 6,
  },
  {
    key: "trabalho",
    name: "Benefício",
    type: FieldTypeEnum.SELECT,
    rules: [{ rule: "required" }],
    rel: { to: "beneficios", key: "id", name: "nome" },
    colSize: 6,
  },
  {
    key: "data_inicio",
    name: "Data de Início",
    type: FieldTypeEnum.DATE,
    colSize: 4,
  },
  {
    key: "data_fim",
    name: "Previsão de Término",
    type: FieldTypeEnum.DATE,
    colSize: 4,
  },
  {
    key: "status",
    name: "Status",
    type: FieldTypeEnum.SELECT,
    rel: { to: "statusOpts", key: "id", name: "label" },
    colSize: 4,
  },
  {
    key: "responsavelTxt",
    name: "Responsável do Projeto",
    type: FieldTypeEnum.TEXT,
    rules: [{ rule: "required" }],
  },
  {
    key: "descricao",
    name: "Descrição/Objetivo do Projeto",
    type: FieldTypeEnum.TEXTAREA,
    ajuda: `Descreva resumidamente qual o objetivo, características e informações que você considera importante no projeto.`,
    rules: [{ rule: "required" }, { rule: "min", params: { size: 3 } }],
  },
  {
    key: "elemento_tecnologico",
    name: "Elemento Novo ou Inovador",
    type: FieldTypeEnum.TEXTAREA,
    ajuda: `O elemento tecnologicamente novo deve representar um progresso científico ou tecnológico. Por progresso científico ou tecnológico compreende-se a aquisição de novos conhecimentos, visando desenvolver/aprimorar produtos, processos e sistemas, o que é a pesquisa aplicada; assim como a comprovação/demonstração de viabilidade técnica ou funcional para produtos, processos, sistemas e serviços ou evidente aperfeiçoamento daqueles já produzidos ou estabelecidos, o que é o desenvolvimento experimental.`,
    rules: [{ rule: "required" }, { rule: "min", params: { size: 3 } }],
  },
  {
    key: "desafio_tecnologico",
    name: "Barreiras ou Desafios Tecnológicos Superáveis",
    ajuda: "Consiste em um determinado problema, dificuldade, limitação ou restrição de ordem técnica imposto ao desenvolvimento, compreensão e implementação das novas tecnologias, ou novos conhecimentos. As atividades realizadas para superar devem ser de P&D, que sempre apresentará um resultado, mesmo que seja um indicativo de que a premissa adotada e testada para superação da barreira não deve ser mais seguida.",
    type: FieldTypeEnum.TEXTAREA,
    rules: [{ rule: "required" }, { rule: "min", params: { size: 3 } }],
  },
];
